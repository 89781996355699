import { Link } from 'gatsby';
import React, { memo } from 'react';
import * as styles from './TopNavbar.module.css';
import Logo from '../shared/Logo';

const TopNavbar = () => (
  <div className={styles.navbar}>
    <div className="container">
      <Link to="/" className={styles.logo}>
        <Logo size="40px" /> <h1 className={styles.h1}>ResumesMadeHere</h1>
      </Link>
    </div>
  </div>
);

export default memo(TopNavbar);
